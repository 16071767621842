const routeNodes = [
  { node: "IMPED", lng: 56.06838900000008, lat: 24.973472000000072 },
  // { node: "D37", lng: 56.05469590000007, lat: 24.984668800000065 },
  // { node: "D38", lng: 56.04100280000006, lat: 24.99586540000007 },
  // { node: "D39", lng: 56.02730980000007, lat: 25.007062000000076 },
  // { node: "D40", lng: 56.01361680000008, lat: 25.018258600000024 },
  // { node: "D41", lng: 55.99992380000003, lat: 25.029455100000064 },
  // { node: "D42", lng: 55.98623070000008, lat: 25.04065170000007 },
  // { node: "D43", lng: 55.97253770000003, lat: 25.051848300000074 },
  { node: "DB520", lng: 55.961583000000076, lat: 25.06080600000007 },
  { node: "DB507", lng: 55.86375000000004, lat: 25.057083000000034 },
  // { node: "D61", lng: 55.85364580000004, lat: 25.04211803000004 },
  // { node: "D62", lng: 55.84354170000006, lat: 25.027152800000067 },
  // { node: "D63", lng: 55.83343750000006, lat: 25.012187480000023 },
  { node: "DB506", lng: 55.82333300000005, lat: 24.99722200000008 },
  // { node: "D44", lng: 55.947962500000074, lat: 25.07190380000003 },
  // { node: "D45", lng: 55.93434170000006, lat: 25.08300200000008 },
  // { node: "D46", lng: 55.92072090000005, lat: 25.09410020000007 },
  // { node: "D47", lng: 55.90710010000004, lat: 25.10519830000004 },
  // { node: "D48", lng: 55.89347930000008, lat: 25.116296500000033 },
  // { node: "D49", lng: 55.87985850000007, lat: 25.127394700000025 },
  // { node: "D50", lng: 55.866237700000056, lat: 25.138492900000074 },
  // { node: "D51", lng: 55.852616900000044, lat: 25.149591100000066 },
  { node: "DB518", lng: 55.84444400000007, lat: 25.156250000000057 },
  // { node: "D52", lng: 55.82878470000003, lat: 25.165000000000077 },
  // { node: "D53", lng: 55.81312500000007, lat: 25.17375000000004 },
  // { node: "D54", lng: 55.797465300000056, lat: 25.18250000000006 },
  { node: "DB517", lng: 55.781806000000074, lat: 25.191250000000025 },
  // { node: "D55", lng: 55.766138900000044, lat: 25.19999310000003 },
  // { node: "D56", lng: 55.75047230000007, lat: 25.208736100000067 },
  // { node: "D57", lng: 55.73480560000007, lat: 25.21747920000007 },
  { node: "VUTON", lng: 55.71913900000004, lat: 25.226222000000064 },
  // { node: "D58", lng: 55.70346530000006, lat: 25.234958300000073 },
  // { node: "D59", lng: 55.68779170000005, lat: 25.24369450000006 },
  // { node: "D60", lng: 55.67211800000007, lat: 25.252430600000025 },
  { node: "DB515", lng: 55.65644400000008, lat: 25.261167000000057 },
  // { node: "D68", lng: 55.64076110000008, lat: 25.269894500000078 },
  // { node: "D69", lng: 55.62507780000004, lat: 25.278622300000052 },
  // { node: "D70", lng: 55.60939440000004, lat: 25.28735000000006 },
  // { node: "D71", lng: 55.593711100000064, lat: 25.296077800000035 },
  { node: "DB514", lng: 55.578028000000074, lat: 25.304806000000042 },
  { node: "DB513", lng: 55.534861000000035, lat: 25.240611000000058 },
  // { node: "D64", lng: 55.55053890000005, lat: 25.23188330000005 },
  // { node: "D65", lng: 55.56621670000004, lat: 25.223155500000075 },
  // { node: "D66", lng: 55.58189440000007, lat: 25.214427800000067 },
  // { node: "D67", lng: 55.59757220000006, lat: 25.205700000000036 },
  { node: "GIRGO", lng: 55.61325000000005, lat: 25.196972000000073 },
  // { node: "D25", lng: 55.62891670000005, lat: 25.18825000000004 },
  // { node: "D26", lng: 55.644583400000045, lat: 25.17952770000005 },
  // { node: "D27", lng: 55.660250000000076, lat: 25.17080550000003 },
  { node: "VELAR", lng: 55.67591700000003, lat: 25.162083000000052 },
  // { node: "D28", lng: 55.69158340000007, lat: 25.153340300000025 },
  // { node: "D29", lng: 55.707250000000045, lat: 25.144597200000078 },
  // { node: "D30", lng: 55.72291670000004, lat: 25.13585420000004 },
  { node: "RIDEV", lng: 55.73858300000006, lat: 25.12711100000007 },
  // { node: "D31", lng: 55.75423610000007, lat: 25.118361100000072 },
  // { node: "D32", lng: 55.76988890000007, lat: 25.109611100000052 },
  // { node: "D33", lng: 55.785541600000045, lat: 25.10086110000003 },
  { node: "DB508", lng: 55.801194000000066, lat: 25.092111000000045 },
  // { node: "D34", lng: 55.81683330000004, lat: 25.08335420000003 },
  // { node: "D35", lng: 55.83247220000004, lat: 25.074597200000028 },
  // { node: "D36", lng: 55.84811110000004, lat: 25.065840300000048 },
  // { node: "D10", lng: 55.80769440000006, lat: 25.00595830000003 },
  // { node: "D11", lng: 55.792055600000026, lat: 25.014694500000076 },
  // { node: "D12", lng: 55.77641670000003, lat: 25.02343060000004 },
  { node: "DB505", lng: 55.76077800000007, lat: 25.032167000000072 },
  // { node: "D7", lng: 55.74513200000007, lat: 25.040909800000065 },
  // { node: "D8", lng: 55.72948610000003, lat: 25.049652800000047 },
  // { node: "D9", lng: 55.71384030000007, lat: 25.05839590000005 },
  { node: "DB504", lng: 55.69819400000006, lat: 25.067139000000054 },
  // { node: "D4", lng: 55.682534700000076, lat: 25.075875000000053 },
  // { node: "D5", lng: 55.66687500000006, lat: 25.084611100000075 },
  // { node: "D6", lng: 55.65087200000005, lat: 25.09334720000004 },
  { node: "ULDOT", lng: 55.635556000000065, lat: 25.10208300000005 },
  // { node: "D1", lng: 55.619771600000036, lat: 25.11087340000006 },
  // { node: "D2", lng: 55.60398770000006, lat: 25.119663600000024 },
  // { node: "D3", lng: 55.588203700000065, lat: 25.128453700000023 },
  { node: "SEPDO", lng: 55.56452800000005, lat: 25.141639000000055 },
  // { node: "D72", lng: 55.54893890000005, lat: 25.150311120000026 },
  // { node: "D73", lng: 55.53335000000004, lat: 25.158983340000077 },
  // { node: "D74", lng: 55.51776110000003, lat: 25.16765556000007 },
  // { node: "D75", lng: 55.502172200000075, lat: 25.176327780000065 },
  { node: "MODUS", lng: 55.48658300000005, lat: 25.185167000000035 },
  { node: "AIRPORT", lng: 55.38016154117974, lat: 25.24389549999564 },
  { node: "GIRGU", lng: 54.5991667, lat: 25.6975 },
  { node: "VUTEB", lng: 54.8637222, lat: 25.6123889 },
  { node: "LOVOK", lng: 55.0339444, lat: 25.5461389 },
  { node: "DB529", lng: 55.1986111, lat: 25.4606944 },
  { node: "PATID", lng: 55.3265, lat: 25.3565833 },
  { node: "DB532", lng: 55.4265833, lat: 25.18575 },
  { node: "RARPI", lng: 55.46972200000005, lat: 25.11194400000005 },
  { node: "TUKAK", lng: 55.53250000000003, lat: 25.076944000000026 },
  // { node: "D13", lng: 55.54812500000003, lat: 25.06819440000004 },
  // { node: "D14", lng: 55.56375000000003, lat: 25.059444400000075 },
  // { node: "D15", lng: 55.57937500000003, lat: 25.050694400000054 },
  { node: "DB522", lng: 55.59500000000003, lat: 25.041944000000058 },
  // { node: "D16", lng: 55.61069450000008, lat: 25.033263900000065 },
  // { node: "D17", lng: 55.62638890000005, lat: 25.024583300000074 },
  // { node: "D18", lng: 55.64208340000005, lat: 25.01590280000005 },
  { node: "DB523", lng: 55.657778000000064, lat: 25.00722200000007 },
  // { node: "D19", lng: 55.673402800000076, lat: 24.998472200000037 },
  // { node: "D20", lng: 55.689027800000076, lat: 24.989722200000074 },
  // { node: "D21", lng: 55.704652800000076, lat: 24.980972200000053 },
  { node: "DB524", lng: 55.720278000000064, lat: 24.972222000000045 },
  // { node: "D22", lng: 55.735902800000076, lat: 24.96347220000007 },
  // { node: "D23", lng: 55.751527800000076, lat: 24.95472220000005 },
  // { node: "D24", lng: 55.767152800000076, lat: 24.94597220000003 },
  { node: "DB525", lng: 55.782778000000064, lat: 24.937222000000077 },
  { node: "DB528", lng: 55.39172200000007, lat: 25.156083000000024 },
  { node: "ITLAB", lat: 25.8236111, lng: 55.8361111 },
  { node: "PUVAL", lat: 25.5994444, lng: 55.7161111 },
  { node: "KEBOG", lng: 55.4631667, lat: 25.5541111 },
  { node: "KUPOR", lng: 55.4138889, lat: 25.4835 },
  { node: "DB526", lng: 55.4489722, lat: 25.3716667 },
  { node: "DB530", lng: 55.4805278, lat: 25.2708333 },
  // { node: "D76", lat: 24.981433296553334, lng: 55.986713711194895 },
  // { node: "D77", lat: 24.989350016968356, lng: 55.905027876160126 },
  // { node: "D78", lat: 24.996352792004718, lng: 55.96844631136266 },
  // { node: "D79", lat: 24.996804994697314, lng: 55.89588926797681 },
  // { node: "D80", lat: 25.0112732610684, lng: 55.95018125309634 },
  // { node: "D81", lat: 25.004261015428966, lng: 55.8867529361198 },
  // { node: "D82", lat: 25.026194771668724, lng: 55.93191834015637 },
  // { node: "D83", lat: 25.011718113241308, lng: 55.87761878349122 },
  // { node: "D84", lat: 25.0290297967127, lng: 55.89243968154252 },
  // { node: "D85", lat: 25.043990958761867, lng: 55.902553901806336 },
  // { node: "D86", lat: 25.2996389, lng: 55.3598611 },
  // { node: "D87", lat: 25.2426944, lng: 55.3932222 },
  // { node: "D88", lat: 25.408652371142434, lng: 55.26258352537985 },
  // { node: "D89", lat: 25.3137083, lng: 55.4035139 },
  { node: "LORID", lng: 54.6511389, lat: 24.93125 },
  { node: "ORGUR", lng: 54.7859722, lat: 25.1035833 },
  { node: "PEBUS", lng: 54.8262778, lat: 25.1549444 },
  { node: "EMOPO", lng: 54.9484444, lat: 25.2860556 },
  { node: "DB531", lng: 55.1231667, lat: 25.3608333 },
  { node: "DB533", lng: 55.2687778, lat: 25.2423611 },
  { node: "DB534", lng: 55.3357778, lat: 25.1876944 },
  { node: "DB528", lng: 55.3917222, lat: 25.1560833 },
  // { node: 'D90', lng: 54.73288904723763, lat: 24.949520914074757 },
  // { node: 'D91', lng: 54.81466342498684, lat: 24.967747204625525 },
  // { node: 'D92', lng: 54.89646200123499, lat: 24.985928821304405 },
  // { node: 'D93', lng: 54.9782847437278, lat: 25.0040657138392 },
  // { node: 'D94', lng: 55.06013161996934, lat: 25.022157832033763 },
  // { node: 'D95', lng: 55.142002597222, lat: 25.040205125768445 },
  // { node: 'D96', lng: 55.2238976425064, lat: 25.058207545000496 },
  // { node: 'D97', lng: 55.30581672260138, lat: 25.07616503976451 },
  // { node: 'D98', lng: 55.38775980404397, lat: 25.094077560172828 },
  // { node: 'D99', lng: 54.69604156449778, lat: 24.988708276046243 },
  // { node: 'D100', lng: 54.74098621777557, lat: 25.0461530653274 },
  // { node: 'D101', lng: 54.86194000787993, lat: 25.104667087324582 },
  // { node: 'D102', lng: 54.937909138278215, lat: 25.105712176108778 },
  // { node: 'D103', lng: 55.013879543194335, lat: 25.10671856385381 },
  // { node: 'D104', lng: 55.089851174615575, lat: 25.10768624815327 },
  // { node: 'D105', lng: 55.165823984517495, lat: 25.108615226693207 },
  // { node: 'D106', lng: 55.241797924864386, lat: 25.1095054972521 },
  // { node: 'D107', lng: 55.317772947609726, lat: 25.11035705770089 },
  // { node: 'D108', lng: 55.39374900469664, lat: 25.111169906003003 },
  // { node: 'D109', lng: 54.861662766232996, lat: 25.110280440244583 },
  // { node: 'D110', lng: 54.937361601710464, lat: 25.116939154093508 },
  // { node: 'D111', lng: 55.01306866222217, lat: 25.123559425736346 },
  // { node: 'D112', lng: 55.088783903480916, lat: 25.13014123944848 },
  // { node: 'D113', lng: 55.164507281122944, lat: 25.136684579591208 },
  // { node: 'D114', lng: 55.240238750708265, lat: 25.143189430611905 },
  // { node: 'D115', lng: 55.315978267721064, lat: 25.14965577704411 },
  // { node: 'D116', lng: 54.90705525825012, lat: 25.15523855533067 },
  // { node: 'D117', lng: 54.98783307699464, lat: 25.15548889316759 },
  // { node: 'D118', lng: 55.068611198217006, lat: 25.155695412833357 },
  // { node: 'D119', lng: 55.14938956389738, lat: 25.15585811376914 },
  // { node: 'D120', lng: 55.230168116013274, lat: 25.155976995534672 },
  // { node: 'D121', lng: 55.3109467965402, lat: 25.156052057808257 },
  // { node: 'D122', lng: 54.86697099253579, lat: 25.198659544064178 },
  // { node: 'D123', lng: 54.90769341005311, lat: 25.242363546055742 },
  // { node: 'D124', lng: 55.02596166550428, lat: 25.266464081642578 },
  // { node: 'D125', lng: 55.10345388852961, lat: 25.246832094923352 },
  // { node: 'D126', lng: 55.18092104780922, lat: 25.227159688829385 },
  // { node: 'D127', lng: 55.25836312229494, lat: 25.20744691239782 },
  // { node: 'D128', lng: 55.00666153496437, lat: 25.311004530580952 },
  // { node: 'D129', lng: 55.064902641684334, lat: 25.335930589665303 },
  // { node: 'D130', lng: 55.17173566089739, lat: 25.321358226441774 },
  // { node: 'D131', lng: 55.220272959852856, lat: 25.281867246284612 },
  // { node: "D132", lat: 25.76757831387416, lng: 55.80606848719407 },
  // { node: "D133", lat: 25.711539366497142, lng: 55.776054227612924 },
  // { node: "D134", lat: 25.65549427904756, lng: 55.74606822689696 },
  // { node: "D135", lat: 25.58815178484404, lng: 55.65285667544792 },
  // { node: "D136", lat: 25.57683197222002, lng: 55.5896142041977 },
  // { node: "D137", lat: 25.56548498106256, lng: 55.526383706651615 },
  // { node: "D138", lat: 25.676266812488436, lng: 54.66534135671451 },
  // { node: "D139", lat: 25.655003789786598, lng: 54.73149242126504 },
  // { node: "D140", lat: 25.633710970871967, lng: 54.79761989141136 },
  // { node: "D141", lat: 25.59032732413372, lng: 54.9204842072648 },
  // { node: "D142", lat: 25.56824384996196, lng: 54.97722527375092 },
  // { node: "D143", lat: 25.517677671789563, lng: 55.08885966213265 },
  // { node: "D144", lat: 25.489195991054093, lng: 55.14374887785398 }
];
export default routeNodes;
