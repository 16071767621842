import { Button, Card, Table } from "antd";
import React from "react";

const AddedFlights = ({
  isTest,
  isFlightList,
  setIsFlightList,
  flightsList,
  deleteFlightList,
}) => {
  const column = [
    {
      title: "AIRCRAFT TPYE",
      key: "aircraft",
      render: (_, record) => record[9],
    },
    {
      title: "Callsign",
      key: "callsign",
      render: (_, record) => record[17],
    },
    {
      title: "Heading",
      //   dataIndex: "heading",
      key: "heading",
      render: (_, record) => record[4],
    },
  ];
  return (
    <div
      className={`${
        isTest && isFlightList ? "show-flight-list" : ""
      } col-12 col-md-4 add-flight`}
    >
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ margin: 0 }}>Added Flight</h2>
          </div>
        }
        style={{
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Table
          columns={column}
          showSorterTooltip={{ placement: "right" }}
          className="aircraft-table m-1"
          size="small"
          dataSource={flightsList}
          rowKey={(data) => data[0]}
          //   scroll={{
          //     x: 600,
          //     // y: 240
          //   }}
          sticky={{ offsetScroll: 0 }}
          pagination={{
            pageSize: 5,
            total: flightsList?.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          bordered
        />
        <div className="d-flex justify-content-around">
          <button
            className="btn btn-primary btn-block mt-2"
            onClick={() => setIsFlightList(false)}
          >
            Add Flights
          </button>
          <button
            className="btn btn-primary btn-block mt-2"
            onClick={deleteFlightList}
          >
            Clear Flights
          </button>
        </div>
      </Card>
    </div>
  );
};

export default AddedFlights;
