// AppContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { closeConnection, connectSocket } from "./tools/socket";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const [socket, setSocket] = useState(null);
  const [aircraftsData, setAircraftsData] = useState([]);

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  // You can add more state and functions here

  useEffect(() => {
    const socketConnection = connectSocket();
    setSocket(socketConnection);

    return () => {
      if (socketConnection) {
        closeConnection(socketConnection);
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      const handleGetFlights = (data) => {
        setAircraftsData(data);
      };
      socket.on("getFlights", handleGetFlights);

      return () => {
        socket.off("getFlights", handleGetFlights);
      };
    }
  }, [socket]);

  return (
    <AppContext.Provider value={{ alert, showAlert, aircraftsData }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
