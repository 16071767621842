import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Viewer,
  Entity,
  GeoJsonDataSource,
  Cesium3DTileset,
  Model,
  CameraFlyTo,
  CameraLookAt,
  Sun,
  Globe,
  Clock,
} from "resium";
import {
  Cartesian3,
  HeadingPitchRoll,
  Math as CesiumMath,
  Ion,
  createWorldTerrainAsync,
  IonResource,
  Transforms,
  SceneMode,
  Ellipsoid,
  JulianDate,
  ClockRange,
  ClockStep,
  SampledPositionProperty,
} from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import aircraftModel from "../../assets/Cesium_Air.glb";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../plugin/AppContext";

const cesiumToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkZmE3ZWE3MC0wMDY0LTQzMTYtOTYzNC00Mjc3NTJjMDlhYWEiLCJpZCI6MjUzMjA0LCJpYXQiOjE3MzA4Njk1MTJ9.Dib4-n54ko5fh9u4zBdWiy9FzlREyHEyvCk9fGWW7z0";
Ion.defaultAccessToken = cesiumToken;

// Set Cesium base URL for assets
window.CESIUM_BASE_URL = "/Cesium";

const defaultAicraftLocation = {
  longitude: 55.728425,
  latitude: 25.0732972,
  altitude: 12000,
  heading: 180,
};

function View3D() {
  const { id } = useParams();
  const { aircraftsData } = useAppContext();
  const [modelMatrix, setModelMatrix] = useState(null);
  const [cameraDest, setCameraDest] = useState(null);
  const [positionProperty, setPositionProperty] = useState(
    new SampledPositionProperty()
  );
  const [terrainProvider, setTerrainProvider] = useState(null);
  const [loading, setLoading] = useState(true);
  const viewerRef = useRef(null);

  const ellipsoid = Ellipsoid.WGS84;

  useEffect(() => {
    const viewer = viewerRef.current?.cesiumElement;

    if (viewer) {
      // Event listener when the scene mode is changed (2D to 3D)
      viewer.scene.morphComplete.addEventListener(() => {
        setLoading(false);
      });

      // Event listener when switching to 3D mode
      viewer.scene.morphStart.addEventListener(() => {
        if (viewer.scene.mode === SceneMode.SCENE3D) {
          setLoading(true);
        }
      });
    }
    return () => {
      if (viewer) {
        viewer.scene.morphComplete.removeEventListener(() => setLoading(false));
        viewer.scene.morphStart.removeEventListener(() => setLoading(true));
      }
    };
  }, []);

  useEffect(() => setTerrainProvider(createWorldTerrainAsync()), []);

  useEffect(() => {
    if (aircraftsData && id) {
      const aircraftData = aircraftsData?.find(
        (aircraft) => aircraft?.identification?.id === id
      );
      if (aircraftData) {
        const { lat, lng, alt, spd, hd, vspd } = aircraftData?.trail[0];
        const origin = Cartesian3.fromDegrees(lng, lat, alt);

        const headingPitchRoll = new HeadingPitchRoll(
          CesiumMath.toRadians(hd),
          0,
          0
        );
        const matrix = Transforms.headingPitchRollToFixedFrame(
          origin,
          headingPitchRoll
        );

        // setting time for animation
        const currentTime = JulianDate.now();
        const futureTime = JulianDate.addSeconds(
          currentTime,
          6,
          new JulianDate()
        );

        // animating old position to new position
        // positionProperty.addSample(currentTime, positionProperty.getValue(currentTime) || origin);
        // positionProperty.addSample(currentTime, cameraDest || origin);
        // positionProperty.addSample(currentTime, origin);
        positionProperty.addSample(futureTime, origin);

        // positionProperty.setInterpolationOptions({
        //   interpolationDegree: 5,
        //   interpolationAlgorithm: CesiumMath.LagrangePolynomialApproximation,
        // });

        // Update camera destination without resetting offset
        // if (!cameraDest || !Cartesian3.equals(cameraDest, origin)) {
          setCameraDest(origin);
        // }

        setModelMatrix(matrix);

        // Adjust rotation by applying HeadingPitchRoll directly to the model matrix
        // Transforms.headingPitchRollToFixedFrame(origin, headingPitchRoll, undefined, matrix);
      }
    }
  }, [aircraftsData, id]);
  return (
    <Viewer
      full
      terrainProvider={terrainProvider}
      ref={viewerRef}
      navigationHelpButton={false} // Disable the navigation help button
      navigationInstructionsInitiallyVisible={false} // Hide navigation instructions
      homeButton={false} // Hide the home button
      // sceneModePicker={true} // Hide scene mode picker (if you want)
      // sceneMode={SceneMode.MORPHING}
      timeline={false} // Hide the timeline (if you want)
      animation={false} // Hide the animation widget (if you want)
      geocoder={false} // Hide geocoder (if you want)
      fullscreenButton={false} // Hide fullscreen button (if you want)
      vrButton={false}
      baseLayerPicker={false}
      infoBox={false}
      scene3DOnly
      selectionIndicator={false}
    >
      {/* <CameraFlyTo
        destination={cameraDest}
        orientation={{
          heading: CesiumMath.toRadians(180), // Rotate to face the back
          pitch: CesiumMath.toRadians(-20), // View angle upwards
          roll: 0,
        }}
        duration={10}
      /> */}
      {/* <Globe enableLighting /> */}
      {/* <Clock
        startTime={JulianDate.fromDate(new Date(2024, 0, 1, 6, 0, 0))}
        currentTime={JulianDate.fromDate(new Date(2024, 0, 1, 6, 0, 0))} // 6:00 AM current
        stopTime={JulianDate.fromDate(new Date(2024, 0, 1, 6, 0, 0))} // Stop after 24 hours
        clockRange={ClockRange.LOOP_STOP} // Time should loop after 24 hours
        clockStep={ClockStep.SYSTEM_CLOCK_MULTIPLIER}
        shouldAnimate
      /> */}
      <Cesium3DTileset url={IonResource.fromAssetId(2275207)} />
      {/* <Sun show={true} size={10000} brightness={1.0} /> */}
      {/* {cameraDest ? (
        <CameraLookAt
          target={cameraDest}
          offset={new Cartesian3(300, 300, 100)}
        />
      ) : null} */}
      {cameraDest ? (
        <Entity
          position={positionProperty}
          model={{
            uri: aircraftModel,
            minimumPixelSize: 128,
            maximumScale: 20000,
            runAnimations:true
          }}
          tracked
        />
      ) : null}
      {/* <Model
        url={aircraftModel}
        modelMatrix={modelMatrix}
        minimumPixelSize={128}
        maximumScale={20000}
        onError={(e) => console.error("Model load error:", e)}
        scale={10}
      /> */}
    </Viewer>
  );
}

export default View3D;
