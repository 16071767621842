import React, { useState } from "react";
import { Button, Modal, Space, Table, Tag } from "antd";
import { Icon } from "@iconify/react";

const { confirm } = Modal;
const color = {
  SH: "error",
  UH: "orange",
  LH: "warning",
  UM: "success",
  LM: "success",
  L: "yellow",
};
const resetData = [
  {
    AirCraftTypeId: "175c8491-9f21-4047-aaef-0bc50bbdd35b",
    Type: "A388",
    Category: "SH",
    Size: "Super Heavey",
  },
  {
    AirCraftTypeId: "5aebd487-7457-4a14-a6bf-f08fe311db32",
    Type: "A333",
    Category: "UH",
    Size: "Upper Heavey",
  },
  {
    AirCraftTypeId: "5aebd487-7457-4a14-a6bf-f08fe311db33",
    Type: "A310",
    Category: "LH",
    Size: "Lower Heavey",
  },
  {
    AirCraftTypeId: "5aebd487-7457-4a14-a6bf-f08fe311db43",
    Type: "A320",
    Category: "UM",
    Size: "Upper Medium",
  },
  {
    AirCraftTypeId: "5aebd487-7457-4a14-a6bf-f08fe311dgh42",
    Type: "GLF6",
    Category: "LM",
    Size: "Lower Medium",
  },
  {
    AirCraftTypeId: "5aebd487-7457-4a14-a6bf-f08fe311db39",
    Type: "C68A",
    Category: "L",
    Size: "Light",
  },
];
export default function AircraftTypes() {
  const [data, setData] = useState(resetData);

  const column = [
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      render: (category) => {
        return <Tag color={color[category]}>{category}</Tag>;
      },
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
    },
    {
      title: "Action",
      key: "Action",
      render: (_, data) => {
        return (
          <Space>
            <Button icon={<Icon icon="bitcoin-icons:edit-filled" />} />
            <Button
              icon={<Icon icon="material-symbols-light:delete" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to delete these items?",
                  // icon: <ExclamationCircleFilled />,
                  // content: "Some descriptions",
                  onOk() {
                    handleDelete(data.AirCraftTypeId);
                  },
                })
              }
            />
          </Space>
        );
      },
    },
  ];
  const handleAdd = () => {
    const newData = {
      AirCraftTypeId: "175c8491-9f21-" + new Date().getTime(),
      Type: "A320",
      Category: "SH",
      Size: "Super Heavey",
    };
    setData([...data, newData]);
  };
  const handleDelete = (id) => {
    const oldData = [...data];
    const newData = oldData.filter((row) => row.AirCraftTypeId !== id);
    setData(newData);
  };
  return (
    <div>
      <Table
        columns={column}
        dataSource={data}
        rowKey={(data) => data.AirCraftTypeId}
        pagination={{
          pageSize: 10,
          total: data.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          // showSizeChanger: true,
          // showQuickJumper: true,
        }}
      />
      <button onClick={handleAdd} className="btn mx-2">
        Add a row
      </button>
    </div>
  );
}
