import { io } from "socket.io-client";

export const connectSocket = () => {
  const socket = io(process.env.REACT_APP_BACKEND_URL, {
    transports: ["websocket", "polling"],
    secure: true,
  });
  socket.on("connect", () => {});
  return socket;
};

export const closeConnection = (socket) => {
  socket.disconnect();
};
