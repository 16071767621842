import "./style.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CircleF,
  GoogleMap,
  Marker,
  OverlayViewF,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import geoJson from "../../routesdata/route.geojson";
import flights from "../../models/flights";
import { Radio, Table } from "antd";
import moment from "moment-timezone";
import Loader from "../../plugin/loader/loader";
import { Icon } from "@iconify/react";
import routeNodes from "../../routesdata/routeNodes";
import { closeConnection, connectSocket } from "../../plugin/tools/socket";
import AddFlights from "../flights/AddFlights";
import AddedFlights from "../flights/AddedFlights";
import { Link } from "react-router-dom";
import { useAppContext } from "../../plugin/AppContext";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 25.0732972,
  lng: 55.728425,
};

const airplaneSymbol = {
  path: "M60 70 c-21 -33 -33 -40 -62 -43 -51 -4 -51 -32 0 -36 29 -3 41 -10 62 -43 29 -43 51 -52 41 -16 -4 12 -9 30 -11 40 -4 13 1 17 25 17 16 0 33 -5 36 -10 13 -20 20 -9 20 30 0 39 -7 50 -20 30 -3 -5 -20 -10 -36 -10 -24 0 -29 4 -25 18 2 9 7 27 11 40 10 35 -12 26 -41 -17z",
  scale: 0.1,
  strokeColor: "#393",
  fillColor: "#393",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 90,
  // anchor: { x: 50, y: 50 }
};

const nodeIcon = {
  path: "M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22",
  scale: 0.3,
  strokeColor: "#3bb0de",
  fillColor: "#66c1e5",
  fillOpacity: 1.0,
  strokeWeight: 1,
};

const params = {
  ...center,
  // lat: 25.248665,
  // lng: 55.352917,
  // lat: 24.973472000000072,
  // lng: 56.06838900000008,
  radius: 150,
  limit: 50,
  airport: "DXB",
};

const yellowZone = [
  { lng: 56.091839410439476, lat: 24.97584556394054 },
  { lng: 55.8420704360596, lat: 25.17970041043945 },
  { lng: 55.57158830247971, lat: 25.32747946139 },
  // { lng: 55.5117302628097, lat: 25.236080596862667 },
  { lng: 55.42979305066693, lat: 25.48048354987348 },
  // { lng: 55.48637240284863, lat: 25.558240374858475 },
  // { lng: 55.45903742514153, lat: 25.577316802848625 },
  { lng: 55.47301890325552, lat: 25.539105890463507 },
  { lng: 55.72687196469291, lat: 25.584231707493974 },
  { lng: 55.858670676884394, lat: 25.830439060629093 },
  { lng: 55.82928313937091, lat: 25.8461706768844 },
  { lng: 55.70535023530709, lat: 25.614657092506025 },
  { lng: 55.45331449674448, lat: 25.569116309536494 },
  { lng: 55.390683197151375, lat: 25.479370725141525 },
  { lng: 55.3221739842619, lat: 25.384486435992666 },
  { lng: 55.20913323121676, lat: 25.473619667209427 },
  { lng: 55.0416207257055, lat: 25.560932540574278 },
  { lng: 54.869767130071736, lat: 25.62792069314199 },
  { lng: 54.5884051267125, lat: 25.718470076202376 },
  { lng: 54.57819662379762, lat: 25.686738426712505 },
  { lng: 54.85767726992826, lat: 25.596857106858007 },
  { lng: 55.02626807429451, lat: 25.53134525942572 },
  { lng: 55.175163701573815, lat: 25.458291264007336 },
  { lng: 55.116609031365456, lat: 25.376155661432147 },
  { lng: 54.93625069279468, lat: 25.297417434463185 },
  { lng: 54.813166351692544, lat: 25.165233603130403 },
  { lng: 54.77284577706549, lat: 25.1138533924398 },
  { lng: 54.627742384625684, lat: 24.928393669505287 },
  { lng: 54.63845653896888, lat: 24.91138260776468 },
  // { lng: 55.31597786878324, lat: 25.343658032790575 },
  // { lng: 55.403783979140584, lat: 25.191728839678834 },
  { lng: 55.46374316032121, lat: 25.08914467914064 },
  { lng: 55.78920890846828, lat: 24.914546044191578 },
  { lng: 55.837141272277876, lat: 24.987888758629627 },
  { lng: 56.08337019613007, lat: 24.955275315739733 },
  { lng: 56.091839410439476, lat: 24.97584556394054 },
];
const redZone = [
  { lng: 55.87018689522279, lat: 25.034408742890992 },
  { lng: 55.48567931783819, lat: 25.247832921317936 },
  { lng: 55.44958367868206, lat: 25.190901517838192 },
  { lng: 55.49239795580854, lat: 25.118374908468272 },
  { lng: 55.80545395580856, lat: 24.943652908468298 },
  { lng: 55.87018689522279, lat: 25.034408742890992 },
];
const tempFlightData = [
  "",
  "8965D7",
  0,
  0,
  0,
  13250,
  338,
  "",
  "F-OMDW1",
  "",
  "A6-FKL",
  0,
  "DOH",
  "DXB",
  "FZ1",
  0,
  2688,
  "",
  0,
];
function Atcm() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [selectedNode, setSelectedNode] = useState(null);
  const [tableOpen, setTableOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [worker, setWorker] = useState(null);
  const [isTest, setIsTest] = useState(false);
  const [isFlightList, setIsFlightList] = useState(true);
  const [flightData, setFlightData] = useState(tempFlightData);
  const [flightsList, setFlightsList] = useState([]);
  const { aircraftsData } = useAppContext();

  const onLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.data.loadGeoJson(geoJson);
    map.data.setStyle({ strokeColor: "red", zIndex: 10 });

    map?.data?.addListener("click", (event) => {
      setSelectedRoute(event);
    });
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    map.data.setMap(null);
    setMap(null);
  }, []);
  const handleZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom());
    }
  };
  const column = [
    {
      title: "SEQUENCE",
      dataIndex: "sequence",
      key: "sequence",
      fixed: "left",
      width: 90,
      sorter: (a, b) => a.sequence - b.sequence,
      defaultSortOrder: "ascend",
      // sortDirections: ["ascend"],
      // render: (_, __, index) => (page - 1) * 5 + index + 1,
    },
    {
      title: "ARRIVAL",
      children: [
        {
          title: "IMPED",
          dataIndex: ["arrivingFrom"],
          key: "Imped",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "IMPED" ? row?.identification?.callsign : "",
        },
        {
          title: "DB529",
          dataIndex: ["arrivingFrom"],
          key: "DB529",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "GIRGU" ? row?.identification?.callsign : "",
        },
        {
          title: "KEBOG",
          dataIndex: ["arrivingFrom"],
          key: "KEBOG",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "ITLAB" ? row?.identification?.callsign : "",
        },
        {
          title: "LORID",
          dataIndex: ["arrivingFrom"],
          key: "LORID",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "LORID" ? row?.identification?.callsign : "",
        },
      ],
    },
    {
      title: "AIRCRAFT TPYE",
      dataIndex: "aircraft",
      key: "aircraft",
      render: (aircraft) => aircraft?.model?.code,
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "SEPARATION (NM)",
      children: [
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>MNM</span>
              <span>4</span>
            </div>
          ),
          dataIndex: ["separation"],
          key: "separation",
          width: 60,
          render: (separation) => separation / 24,
        },
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>+</span>
              <span>1</span>
            </div>
          ),
          dataIndex: ["safeSeparation"],
          key: "safeSeparation",
          width: 60,
          render: (safeSeparation) => safeSeparation / 24,
        },
      ],
      // dataIndex: "separation",
      // key: "separation",
      // render: (separation) => separation / 24,
    },
    {
      title: "GAP (NM)",
      dataIndex: "aircraftGap",
      key: "aircraftGap",
      render: (aircraftGap) =>
        (Math.floor(Number(aircraftGap) * 10) / 10).toFixed(1),
    },
    {
      title: "ESTIMATE ARR. TIME (UTC)",
      dataIndex: "estimateArrivalTime",
      key: "estimateArrivalTime",
      render: (estimateArrivalTime) => {
        let arrTime = moment(estimateArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
      sorter: (a, b) =>
        a.estimateArrivalTime?.localeCompare(b.estimateArrivalTime),
      // sortDirections: ["ascend"],
      // defaultSortOrder: "ascend",
    },
    {
      title: "DELAY",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => {
        const sign = delay < 0 ? "-" : "";
        const momentObject = moment.duration(Math.abs(delay));

        const hours = momentObject.hours();
        const minutes = momentObject.minutes();
        const seconds = momentObject.seconds();

        return `${sign}${hours}:${minutes}:${seconds}`;
      },
    },
    {
      title: "CALCULATED ARR. TIME (UTC)",
      dataIndex: "calculatedArrivalTime",
      key: "calculatedArrivalTime",
      render: (calculatedArrivalTime) => {
        let arrTime = moment(calculatedArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
    },
    {
      title: "REMAINING DISTANCE (NM)",
      dataIndex: "remainingDistance",
      key: "remainingDistance",
      render: (remainingDistance) =>
        (Math.floor(Number(remainingDistance) * 10) / 10).toFixed(1),
      sorter: (a, b) => a.remainingDistance - b.remainingDistance,
    },
    {
      title: "AIRCRAFT STATUS",
      dataIndex: "isOnRoute",
      key: "isOnRoute",
      render: (isOnRoute) => (isOnRoute ? "on route" : "off route"),
    },
    {
      title: "ROUTE STATUS",
      dataIndex: "route",
      key: "route",
      render: (route) => (route.length ? "available" : "on hold"),
    },
    {
      title: "BASE TURN",
      dataIndex: "baseTurn",
      key: "baseTurn",
    },
    {
      title: "CROSSING ALTITUDE",
      dataIndex: "crossingAltitude",
      key: "crossingAltitude",
    },
  ];

  const getModifiedFlightData = useMemo(() => {
    if (!data.length) return [];
    const modifiedFlightData = data?.filter(
      (flight) => flight?.estimateArrivalTime
    );
    return modifiedFlightData.map((flight, index) => ({
      sequence: index + 1,
      ...flight,
    }));
  }, [data]);

  useEffect(() => {
    if (selectedFlight) {
      const isFound = data?.find(
        (flight) =>
          flight?.identification?.id === selectedFlight?.identification?.id
      );

      setSelectedFlight(isFound ? isFound : null);
    }
  }, [data]);

  const getFlights = (params) => {
    setIsLoading(true);
    flights
      .getRadarFlights(params)
      .then((data) => {
        // setData(data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteOnRouteFlights = () => {
    setIsLoading(true);
    flights
      .deleteOnRouteFlights()
      .then((resolve) => {
        console.log("delete success =>", resolve);
      })
      .catch((reject) => {
        console.log("delete error =>", reject);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFlightList = () => {
    flights
      .getFlightList()
      .then((list) => {
        setFlightsList(list.FlightList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const deleteFlightList = () => {
    setIsLoading(true);
    flights
      .deleteFlightList()
      .then()
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        getFlightList();
        setIsLoading(false);
      });
  };

  const addFlight = () => {
    let tempData = [...flightData];
    tempData[0] = new Date().getTime();
    const data = { flightData: tempData };
    setIsLoading(true);
    flights
      .addFlight(data)
      .then(() => {
        getFlightList();
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
        setFlightData(tempFlightData);
      });
  };
  const getTestMode = () => {
    flights
      .getTestMode()
      .then((data) => {
        setIsTest(data.TestMode);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const updateTestMode = (event) => {
    // setIsTest(event.target.value);
    const mode = {
      TestMode: event.target.value,
    };
    flights
      .updateTestMode(mode)
      .then()
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        getTestMode();
        getFlightList();
      });
  };

  useEffect(() => {
    getFlightList();
    getTestMode();
  }, []);

  useEffect(() => {
    if (aircraftsData) {
      setData(aircraftsData);
    }
  }, [aircraftsData]);

  // Function to send a message to the worker thread
  const sendMessageToWorker = () => {
    if (worker) {
      worker.postMessage("hello");
    }
  };

  const flightDataChange = (ind, value) => {
    let tempData = [...flightData];
    tempData[ind] = value;
    setFlightData(tempData);
  };

  useEffect(() => {
    const newWorker = new Worker(
      new URL("../../plugin/workerThread/worker.js", import.meta.url)
    );
    setWorker(newWorker);

    return () => {
      newWorker?.terminate();
    };
  }, []);

  useEffect(() => {
    if (worker) {
      worker.onmessage = function (message) {
        console.log("Received message from worker:", message.data);
      };
    }
    sendMessageToWorker();
  }, [worker]);

  return (
    <>
      {isLoading && <Loader />}
      {/* Manzer Ansari */}
      <div className="main-container">
        <div
          id="map"
          className={`container-fluid pt-2 ${tableOpen ? "reduce-height" : ""}`}
        >
          <div className={`options-panel`}>
            <Link
              className="btn"
              to={`${
                selectedFlight
                  ? `/3d/${selectedFlight?.identification?.id}`
                  : "/"
              }`}
            >
              3D
            </Link>
            <div className="handle-btn-container p-2">
              <span className="handle-btn"></span>
            </div>
          </div>
          <div className="toggle-flights">
            <Radio.Group
              block
              options={[
                { label: "Live", value: false },
                { label: "Test", value: true },
              ]}
              value={isTest}
              optionType="button"
              buttonStyle="solid"
              onChange={updateTestMode}
            />
          </div>
          <AddFlights
            isTest={isTest}
            isFlightList={isFlightList}
            setIsFlightList={setIsFlightList}
            flightData={flightData}
            flightDataChange={flightDataChange}
            addFlight={addFlight}
          />
          <AddedFlights
            isTest={isTest}
            isFlightList={isFlightList}
            setIsFlightList={setIsFlightList}
            flightsList={flightsList}
            deleteFlightList={deleteFlightList}
          />
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onZoomChanged={handleZoomChanged}
              onClick={() => {
                setSelectedFlight(null);
                setSelectedNode(null);
                setSelectedRoute(null);
              }}
              onDblClick={(e) => {
                if (isTest) {
                  let tempData = [...flightData];
                  tempData[2] = e.latLng.lat();
                  tempData[3] = e.latLng.lng();
                  setFlightData(tempData);
                }
              }}
              options={{
                disableDoubleClickZoom: isTest,
              }}
            >
              {/* <CircleF
              center={{
                lat: 25.0732972,
                lng: 55.728425,
              }}
              radius={120000}
              options={{
                fillColor: 'red',
                fillOpacity: 0.4,
                strokeColor: 'red',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            /> */}
              {selectedFlight && (
                <>
                  <OverlayViewF
                    position={selectedFlight.trail[0]}
                    mapPaneName="overlayMouseTarget"
                  >
                    <div className="custome-overlay">
                      <span className="fw-bold">
                        {selectedFlight?.identification?.callsign}
                      </span>
                    </div>
                  </OverlayViewF>
                  <Polyline
                    path={selectedFlight?.route}
                    options={{
                      geodesic: true,
                      strokeColor: "#39FF14",
                      strokeOpacity: 1.0,
                      strokeWeight: 3,
                      zIndex: 20,
                    }}
                  />
                </>
              )}
              <Polyline
                path={redZone}
                options={{
                  geodesic: true,
                  strokeColor: "red",
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  zIndex: 20,
                }}
              />
              <Polyline
                path={yellowZone}
                options={{
                  geodesic: true,
                  strokeColor: "yellow",
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  zIndex: 20,
                }}
              />
              {routeNodes.map((nodeData, index) => (
                <Marker
                  key={index}
                  position={nodeData}
                  icon={{
                    // url: pointer,
                    ...nodeIcon,
                    // scaledSize: new window.google.maps.Size(
                    //   selectedNode === index ? 12 : 6,
                    //   selectedNode === index ? 12 : 6
                    // ),
                    anchor: new window.google.maps.Point(12, 12),
                  }}
                  onClick={(e) => {
                    setSelectedNode({
                      latLng: e.latLng,
                      nodeData,
                    });
                  }}
                />
              ))}

              {getModifiedFlightData?.map((flightDetails, index) => {
                const { separationIndicator, trail, identification } =
                  flightDetails;
                let zoomLevel = zoom < 10 ? zoom + 0.5 * (10 - zoom) : zoom;
                let iconSize = 0.012 * zoomLevel;
                let scale = iconSize < 0.08 ? 0.08 : iconSize;
                if (trail[0]) {
                  return (
                    <Fragment key={identification?.id}>
                      <Marker
                        position={trail[0]}
                        icon={{
                          ...airplaneSymbol,
                          rotation: Number(trail[0]?.hd) + 90,
                          scale: scale,
                        }}
                        onClick={() => setSelectedFlight(flightDetails)}
                      />
                      {Object.keys(separationIndicator).length ? (
                        <OverlayViewF
                          position={separationIndicator}
                          mapPaneName="overlayMouseTarget"
                        >
                          <div
                            className="separation-indicator"
                            style={{
                              height: `${2.5 * zoom}px`,
                              width: `${2.5 * zoom}px`,
                            }}
                          >
                            <div className="dot"></div>
                          </div>
                        </OverlayViewF>
                      ) : null}
                    </Fragment>
                  );
                }
                return null;
              })}
              {selectedNode && (
                <OverlayViewF
                  position={selectedNode.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold">
                      {selectedNode?.nodeData?.node}
                    </span>
                  </div>
                </OverlayViewF>
              )}
              {selectedRoute && (
                <OverlayViewF
                  position={selectedRoute?.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold text-uppercase">
                      {selectedRoute?.feature?.getProperty("Name")}
                    </span>
                  </div>
                </OverlayViewF>
              )}
            </GoogleMap>
          ) : null}
        </div>
        <div className={`table-container ${tableOpen ? "table-open" : ""}`}>
          <div className="handle-container">
            <div
              className="table-handle"
              onClick={() => setTableOpen(!tableOpen)}
            >
              <span></span>
              <span></span>
            </div>
            <Icon
              icon="ic:round-refresh"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                getFlights(params);
              }}
            />
            <Icon
              icon="material-symbols-light:delete"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                deleteOnRouteFlights();
              }}
            />
          </div>
          <div
            className={`table-content scrollbar-none ${
              tableOpen ? "overflow-auto" : "overflow-hidden"
            }`}
          >
            <Table
              columns={column}
              showSorterTooltip={{ placement: "right" }}
              className="aircraft-table m-1"
              rowClassName={(record) => (record.route.length ? "" : "on-hold")}
              size="small"
              dataSource={getModifiedFlightData}
              rowKey={(data) => data?.identification?.id}
              scroll={{
                x: 1600,
                // y: 240
              }}
              sticky={{ offsetScroll: 0 }}
              pagination={{
                pageSize: 30,
                current: page,
                total: getModifiedFlightData?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (current) => setPage(current),
              }}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Atcm;
